<template>
  <div ref='viewer'>
      <popup ref="pdfSaveDialog"
             title="Saving PDF Document"
             okText="Okay"
             okColor="green"
             :isPersistent=true
             :showClose=false
             @savePromise="pdfSaveComplete">
        <template v-slot:default="slotProps">
          <h3>Action Completed</h3>
          {{ pdfResponse }}
        </template>
      </popup>
    </div>
</template>

<script>
/* eslint-disable */
import PDFJSExpress from '@pdftron/pdfjs-express'
import WebViewer from '@pdftron/pdfjs-express'
import {VueController} from '@/service/support/vuex-controller'
import vaultService from "@/service/vault-service";
import {EventBus} from "@/service/support/event-bus";

export default VueController('WebViewer', {
  props: {
    docUrl: String,
    xfdfOverride: String,
    file: Object,
    vault: Object
  },
  watch: {
    docUrl: function () {
      if (this.instance) {
        this.instance.UI.loadDocument(this.docUrl + '', {extension: "pdf"});
      }
    }
  },
  data() {
    return {
      instance: null,
      savePdfTrigger: false,
      pdfResponse: "",
      saveButtonActivator: false,
      pdfFormDirty: false,
      isViewOnly: false,
      signatureChanged: false
    }
  },
  state: [
    "organization",
    "user"
  ],
  mounted: function () {
    let self = this;

    PDFJSExpress({
      path: '/public/pdfjsexpress',
      licenseKey: 'k5VcP4BL3vzqf9c806Dv',
      disableFlattenedAnnotations: false,
      isReadOnly: self.isViewOnly,
      initialDoc: self.docUrl && self.docUrl !== '' ? self.docUrl : undefined,
      disabledElements: [
        "fileAttachmentToolGroupButton",
        "linkButton",
        "calibrateButton",
        "stampToolGroupButton",
        "polygonCloudToolGroupButton"
      ]
    }, self.$refs.viewer).then(async (instance) => {
      self.instance = instance;
      instance.UI.setHeaderItems((header) => {
        const items = header.getItems();
        items.splice(7, 1);
        header.update(items);

        let defaultHeader = header.headers.default;
        defaultHeader.push({
          img: "icon-header-full-screen",
          index: -1,
          type: "actionButton",
          element: 'fullScreenButton',
          hidden: [ 'mobile' ],
          onClick: () => {
            instance.UI.toggleFullScreen()
          }
        });

        defaultHeader.splice(7, 0, {
          type: 'divider'
        });

        defaultHeader.splice(7, 0, {
          "type": "actionButton",
          //"toolGroup": "signatureTools",
          img: '../../../sign.svg',
          "dataElement": "signatureToolGroupButton",
          "title": "annotation.signature",
          onClick: () => {
            instance.UI.openElement("signatureModal");
          },
        });

        defaultHeader.splice(7, 0, {
          "type": "toolGroupButton",
          "toolGroup": "freeTextTools",
          "dataElement": "freeTextToolGroupButton",
          "title": "annotation.freetext"
        });

        instance.UI.setToolbarGroup('toolbarGroup-View');
        if (!self.isViewOnly) {
          //instance.UI.setToolbarGroup('toolbarGroup-FillAndSign');
        }
        //console.log("Default header: " + JSON.stringify(defaultHeader));
      });

      instance.Core.documentViewer.addEventListener('documentLoaded', async () => {
        self.$emit("updatePdfDirtyFlag", false);
        self.configurePdfViewerSettings(instance);
      });

      let updateFieldChanged = (field, value) => {
        self.pdfFormDirty = true;
        if (!self.isViewOnly) {
          self.$emit("updatePdfDirtyFlag", true);
        }
      };

      let updateAnnotationChanged = (annotations, action, e) => {
        self.pdfFormDirty = true;
        if (!e.imported) {
          if (!self.isViewOnly) {
            self.$emit("updatePdfDirtyFlag", true);
          }

          if (annotations.length > 0 && annotations[0].Subject === "Signature") {
            self.signatureChanged = true;
          }
        }
      };

      instance.Core.documentViewer.addEventListener('annotationsLoaded', async () => {
        //instance.Core.annotationManager.enableReadOnlyMode();
        instance.setLayoutMode(instance.LayoutMode.Continuous);
        instance.setToolMode('Pan');
        const signatureWidgetAnnots = instance.Core.annotationManager.getAnnotationsList().filter(
            annot => annot instanceof instance.Core.Annotations.SignatureWidgetAnnotation
        )
        signatureWidgetAnnots.forEach((widget) => {
          const isSigned = widget.isSignedDigitally();
          if (isSigned) {
            instance.Core.annotationManager.deleteAnnotations([widget]);
          } else {
          }
        });
        if (self.xfdfOverride) {
          let annots = instance.Core.annotationManager.getAnnotationsList();

          instance.Core.annotationManager.removeEventListener('fieldChanged', updateFieldChanged);
          instance.Core.annotationManager.removeEventListener('annotationChanged', updateAnnotationChanged);
          //instance.Core.annotationManager.removeEventListener('annotationsDrawn', updateDrawingMade);

          // remove annotations
          instance.Core.annotationManager.deleteAnnotations(annots, {force: true});
          instance.Core.annotationManager.importAnnotations(self.xfdfOverride).then(importedAnnotations => {
            console.log("Override with saved FDF annotations.");
            instance.Core.annotationManager.addEventListener('fieldChanged', updateFieldChanged);
            instance.Core.annotationManager.addEventListener('annotationChanged', updateAnnotationChanged);
          });
          if (self.isViewOnly) {
            instance.Core.annotationManager.enableReadOnlyMode();
          }
        } else {
        if (self.isViewOnly) {
          instance.Core.annotationManager.enableReadOnlyMode();
        }
          instance.Core.annotationManager.addEventListener('fieldChanged', updateFieldChanged);
          instance.Core.annotationManager.addEventListener('annotationChanged', updateAnnotationChanged);
          /*setTimeout(() => {
            instance.Core.annotationManager.addEventListener('annotationsDrawn', updateDrawingMade);
          }, 3000);*/
        }
      });
    });
  },
  unmounted() {
    let self = this;
    self.instance.UI.closeDocument().then(function () {
      self.instance.$destroy();
    })
  },
  methods: {
    configurePdfViewerSettings(instance) {
      let self = this;

      //instance.setLayoutMode(instance.LayoutMode.Single);
      instance.UI.setFitMode(instance.FitMode.Zoom);
      instance.UI.setZoomLevel("100%");

      self.isViewOnly = false;
      instance.Core.annotationManager.disableReadOnlyMode();

      instance.setToolMode('Pan');

      const customStyles = (widget) => {
        if (widget instanceof instance.Core.Annotations.ChoiceWidgetAnnotation) {
          widget.getElement().style.border = 'none';
        }

        if (widget instanceof instance.Core.Annotations.ListWidgetAnnotation) {
          widget.getElement().style.border = 'none';
        }

        if (widget instanceof instance.Core.Annotations.CheckButtonWidgetAnnotation) {
          widget.getElement().style['background-color'] = "#f3faff";
        }

        if (widget instanceof instance.Core.Annotations.RadioButtonWidgetAnnotation) {
          widget.getElement().style['background-color'] = "#f3faff";
        }

        if (widget instanceof instance.Core.Annotations.TextWidgetAnnotation) {
          widget.getElement().style['background-color'] = "#f3faff";
        }

        return {
          'background-color': 'rgba(134, 146, 254, 0.15)',
          'border': '1px solid rgba(134, 146, 254, 0.65)',
          'color': 'black'
        };
      };

      instance.UI.disableDesktopOnlyMode();
      instance.Core.annotationManager.setCurrentUser(self.user.displayName);
      instance.Core.Annotations.WidgetAnnotation.getCustomStyles = customStyles;

      //instance.setFitMode(instance.FitMode.FitWidth);
      //instance.UI.disableElements(['fullscreenButton'])


    },
    pdfSaveComplete() {
      this.pdfFormDirty = false;
      //this.$emit("savePdfToParent");
      this.savePdfTrigger = !this.savePdfTrigger;
    },
    async savePdf() {
      let self = this;
      self.savePdfTrigger = !self.savePdfTrigger;
      let xfdf = "";

      try {
        xfdf = await self.createXfdf();
        if (xfdf) {
          vaultService.getSignedFileUrl(self.file, {id: self.file.vault_id, org_id: self.file.org_id}, false).then(fileUrl => {
            vaultService.mutation("savePdfForm", {
              fdf_data: btoa(xfdf),
              vault_id: self.file.vault_id,
              file_id: self.file.id,
              fdf_url: fileUrl
            }).then(response => {
              if (response) {
                self.pdfResponse = "PDF has saved successfully!";
                self.notification("success", 'PDF field information has been saved.');
                self.savePdfTrigger = false;
                //self.pdfSaveComplete = true;
                self.loadingSave = false;
                this.pdfFormDirty = false;
                self.$emit("savePdfToParent", xfdf, self.file, self.signatureChanged);
                self.signatureChanged = false;
              } else {
                self.pdfResponse = "PDF has failed to save, please try again or contact support.";
                self.openPopup('pdfSaveDialog');
                self.savePdfTrigger = false;
                //self.pdfSaveComplete = true;
                self.loadingSave = false;
              }
            }).catch(error => {
              console.error("Failure to save PDF. " + error);
              self.notification("error", 'Unable to save PDF, please try again.');
              self.savePdfTrigger = false;
              //self.pdfSaveComplete = true;
              self.loadingSave = false;
            });
          }).catch(genError => {
            console.error("Failure to save PDF." + genError);
            self.notification("error", 'Unable to save PDF file, please try again.');
            self.savePdfTrigger = false;
            //self.pdfSaveComplete = true;
            self.loadingSave = false;
          });
        } else {
          console.error("XFDF Data failure : " + xfdf);
          self.savePdfTrigger = false;
          //self.pdfSaveComplete = true;
          self.loadingSave = false;
        }
      } catch (error) {
        console.error("XFDF Root Data failure : " + xfdf);
        self.savePdfTrigger = false;
        //self.pdfSaveComplete = true;
        self.loadingSave = false;
      }
    },
    async createXfdf() {
      let self = this;
      try {
        let xfdf = await self.instance.Core.annotationManager.exportAnnotations({widgets: true, links : true, fields:true});
        return xfdf;
      } catch (e) {
        console.error(e);
        return JSON.stringify(e);
      }
    }
  }
});
</script>

<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style>
